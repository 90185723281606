import { makeStyles, Typography } from '@material-ui/core';
import { PageProps } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

interface Props extends PageProps {}

const IndexPage: React.FC<Props> = () => {
  const classes = useStyles();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => setMounted(true), []);
  return (
    <div className={classes.root}>
      <Helmet>
        <meta name="description" content="There's nothing to see here." />
      </Helmet>
      <Typography variant="h1" className={classNames(classes.quote, mounted && classes.quoteVisible)}>
        There’s nothing to see here.
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.contrastText,
    justifyContent: 'center',
    alignItems: 'center',
  },
  quote: {
    color: '#eee',
    fontFamily: 'Roboto Slab',
    margin: '1em',
    opacity: 0,
    transition: 'opacity 0.5s ease-out',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: 80,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 60,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  quoteVisible: {
    opacity: 1,
  },
}));

export default IndexPage;
